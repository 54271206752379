import { useEffect, useState } from 'react';
import { getParameter } from '@SharedScripts/Utils/Url';

const useQueryParameterChange = (queryKey: string) => {
  const [locationChanged, setLocationChanged] = useState(getParameter(queryKey));

  const handleEvent = (event: CustomEvent | any) => {
    if (event.detail.queryKey === queryKey && event.detail.queryValue !== locationChanged) {
      if (event.detail.state === 'removed') setLocationChanged('');
      else setLocationChanged(event.detail.queryValue ?? event.detail.queryKey);
    }
  };

  useEffect(() => {
    document.addEventListener('danskespil:locationChanged', handleEvent);

    return () => {
      document.removeEventListener('danskespil:locationChanged', handleEvent);
    };
  }, []);
  return locationChanged;
};

export default useQueryParameterChange;
