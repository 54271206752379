﻿import React, { useState, useEffect, useRef } from 'react';
import useIsLoggedIn from '@SharedScripts/Hooks/useIsLoggedIn';
import { getGame, getGameLauncher, getLiveData, getScreenNameData } from '../../api/CasinoApi';
import { useQuery } from '@tanstack/react-query';
import MicroTileSlider from '../Sliders/MicroTileSlider';
import GameLauncherHeader from './GameLauncherHeader';
import TopSection, { TopSectionProps } from '../GamePage/TopSection';
import {
  appendParameter,
  appendParameterAutomatically,
  getAnchor,
  getParameter,
  removeParameterAutomatically
} from '@SharedScripts/Utils/Url';
import { isMobile, isTablet, isDesktop, isFullscreen } from '@SharedScripts/Utils/DeviceClass';
import GameLoader from './GameLoader';
import { Header } from '../../Components/Header/Header';
import { Paragraph } from '../../Components/Paragraph/Paragraph';
import { Button } from '../../Components/Button/Button';
import { getLoginUrl } from '@SharedScripts/UserData/Authentication';
import useQueryParameterChange from '@SharedScripts/Hooks/useQueryParameterChange';
import { GameTypes } from '../../apiTypes/GameService';
import { track } from '../../helpers/tracking';
import { CountDown } from '../../Components/CountDown/CountDown';
import { BingoGame } from '../../Types';

interface GameLauncherProps {
  game: ShortGame,
  paymentLink: PaymentLink,
  disableScreenNameLookup: boolean
}

type GameLaunchProvider = {
  name: string,
}

export type ShortGame = {
  gameServiceId: string,
  backgroundUrl: string,
  minWager: string,
  type: GameType,
  launchProvider: GameLaunchProvider,
  assets: Array<SitecoreImage> | []
}

export type SitecoreImage = {
  alt?: string;
  border?: string | null;
  class?: string | null;
  height: number | null;
  width: number | null;
  url: string;
  mimeType?: string
};

export interface PaymentLink {
  text: string,
  url: string
}
export type SeatSelectorEvent = {
  detail: {
    context: {
      seat: number
    }
  }
}

type GameType = {
  name: string
}

const GameLauncher = ({
  game,
  paymentLink,
  disableScreenNameLookup = false
}: GameLauncherProps) => {
  const [seat, setSeat] = useState<number | null>(null);
  const parameterAutoLaunch = Boolean(getParameter('launch')) ;
  const isLoggedIn = useIsLoggedIn();
  const [gameLaunchUrl, setGameLaunchUrl] = useState('');
  const [backgroundUrl, setBackgroundUrl] = useState('');
  const queryParameterChanged = useQueryParameterChange('launch');
  const [liveTableIsFilledUp, setLiveTableIsFilledUp] = useState(false);
  const [isTableClosed, setIsTableClosed] = useState(false);
  const [openFrom, setOpenFrom] = useState<string | null>(null);
  const ctaText = liveTableIsFilledUp ? 'Bet behind' : 'Spil nu';
  const anchor = getAnchor(true);
  const returnUrl = getParameter('returnUrl') + anchor;
  const userBalanceMainManu = document.querySelector('.js-balance-user');
  const userBalanceMainManuCasinoReplacement = document.querySelector('.js-balance-casino-replacement');
  const isEntainGame = game.launchProvider.name === 'Entain';
  const [hasScreenName, setHasScreenName] = useState<boolean | null>(null);
  const screenNameBingoGame =  BingoGame.id;
  const isEntainGameOnDesktopWithScreenName = (isEntainGame && hasScreenName && isDesktop());
  const isEntainGameNotOnDesktop = (isEntainGame && !isDesktop());
  const ignoreScreenNameData = disableScreenNameLookup;
  const gameLauncherRef = useRef<HTMLDivElement>(null);

  // TODO validate returnUrl

  const playForMoney = useQuery({
    queryKey: [{ id: 'gameServiceId' + game?.gameServiceId + seat }],
    queryFn: () => getGameLauncher(game?.gameServiceId, 'REAL', (seat ?? 0)),
    enabled: isLoggedIn && (isEntainGameOnDesktopWithScreenName || isEntainGameNotOnDesktop || !isEntainGame || ignoreScreenNameData) && (parameterAutoLaunch || !!queryParameterChanged)
  });

  const gameData = useQuery({
    queryKey: ['getGame', game?.gameServiceId],
    queryFn: () => getGame(game?.gameServiceId)
  });

  const userScreenNameData = useQuery({
    queryKey: ['userScreenNameData', screenNameBingoGame],
    queryFn: () => getScreenNameData(screenNameBingoGame),
    enabled: isLoggedIn && isEntainGame && !ignoreScreenNameData && isDesktop()
  });

  if (game?.type?.name === GameTypes.LIVE && gameData?.data) {

    const liveData = getLiveData([game.gameServiceId]);
    liveData.then((liveGame) => {
      const liveDataTable = liveGame[0]?.tableData;

      if (liveDataTable?.open) {
        setIsTableClosed(false);
      } else {
        setIsTableClosed(true);
      }

      if (liveDataTable?.tableType === 'Blackjack' && liveDataTable?.playerSeated?.length === liveDataTable?.totalSeats) {
        setLiveTableIsFilledUp(true);
      }

      if (gameData?.data?.graphics?.background?.url && liveDataTable?.imageUrls?.imageUrlLarge) {
        setBackgroundUrl(liveDataTable?.imageUrls.imageUrlLarge);
      }

      if (liveDataTable?.openingHours?.openFrom) {
        setOpenFrom(liveDataTable?.openingHours?.openFrom);
      }
    });
  }

  const toggleFullscreen = () => {
    if (isFullscreen()) {
      removeFullscreenClasses();
      removeParameterAutomatically('fullscreen');
    } else {
      window.scroll({ top: 0 });
      addFullscreenClasses();
      appendParameterAutomatically('fullscreen');
    }
  };

  const changeSeat = (data: SeatSelectorEvent) => {
    if (!isLoggedIn) {
      window.location.href = getLoginUrl({});
    }

    setSeat(data.detail.context.seat);
    appendParameterAutomatically('launch');

  };

  const addFullscreenClasses = () => {
    document.body.classList.add('group');
    document.body.classList.add('is-fullscreen');
    document.body.style.overflow = 'hidden';
  };

  const removeFullscreenClasses = () => {
    document.body.classList.remove('group');
    document.body.classList.remove('is-fullscreen');
    document.body.style.overflow = 'auto';
  };

  const hideUserBalance = () => {
    if (userBalanceMainManu) {
      userBalanceMainManu.classList.add('hidden');

      if (!userBalanceMainManuCasinoReplacement) {
        const casinoHtmlMyAccount = document.createElement('div');
        casinoHtmlMyAccount.className = 'js-balance-casino-replacement';
        casinoHtmlMyAccount.innerHTML = 'Min profil';
        userBalanceMainManu.parentNode?.appendChild(casinoHtmlMyAccount);
      }
    }
  };

  const insertEntainPromoPage = () => {
    const ifrm = document.createElement('iframe');
    ifrm.setAttribute('src', 'https://promo.danskespil.dk/da/promo/offers?.box=1&iFrameEnable=true&hideHeaderFooter=true');
    ifrm.style.width = '1px';
    ifrm.style.height = '1px';
    ifrm.style.position = 'absolute';
    document.querySelectorAll('main').item(0).appendChild(ifrm);
  };

  useEffect(() => {
    if (gameData.data?.graphics?.background?.url) {
      setBackgroundUrl(gameData.data.graphics.background.url);
    }
  }, [gameData?.data]);

  useEffect(() => {
    if (!userScreenNameData.data) {
      return;
    }

    if (userScreenNameData.data.screenName === null) {
      setHasScreenName(false);
      insertEntainPromoPage();
    } else {
      setHasScreenName(true);
    }
  }, [userScreenNameData]);

  useEffect(() => {
    document.addEventListener('casino:gamelaunch:seatselector', (data) => changeSeat(data as unknown as SeatSelectorEvent));

    if (isLoggedIn && isDesktop() && !parameterAutoLaunch) {
      appendParameterAutomatically('launch');
    }
  }, []);

  useEffect(() => {
    const shouldGoFullscreen = (isLoggedIn && (isTablet() || isMobile()) && parameterAutoLaunch) || // mobile and tablet users can get see the maximized / fullscreen if logged in
      (isDesktop() && getParameter('fullscreen') && isLoggedIn);

    if (shouldGoFullscreen) {
      toggleFullscreen();
    }

  }, [isLoggedIn, parameterAutoLaunch]);

  useEffect(() => {
    if (playForMoney.data?.gameLaunchUrl) {
      const tempGameLaunchUrl = new URL(playForMoney.data.gameLaunchUrl);
      tempGameLaunchUrl.searchParams.set('lobbyURL', returnUrl);
      setGameLaunchUrl(tempGameLaunchUrl.href);
    }
  }, [playForMoney.data?.gameLaunchUrl]);

  const TopSectionForNotLoggedInOrMissingScreenName = ({ showImage, hasScreenName }: Pick<TopSectionProps, 'showImage' | 'hasScreenName'>) => {
    if (isLoggedIn && hasScreenName === null) { // might be null - init value
      return;
    }

    if (isLoggedIn && hasScreenName === false) {
      return <TopSection gameServiceId={game.gameServiceId} gameType={game.type.name} backgroundUrl={backgroundUrl} gameIsPending={gameData.isPending} isLoggedIn={isLoggedIn} showImage={showImage} gameTitle={gameData?.data?.title ?? ''}>
        <Button text='Angiv dit brugernavn'
          size='large'
          as={'a'}
          href={`https://myaccount.danskespil.dk/da/mobileportal/screenname?rurl=${window.location.href}`}
          variant='light'
        />
      </TopSection>;
    }

    return (<TopSection gameServiceId={game.gameServiceId} gameType={game.type.name} backgroundUrl={backgroundUrl} gameIsPending={gameData.isPending} isLoggedIn={isLoggedIn} showImage={showImage} gameTitle={gameData?.data?.title ?? ''}>
      <Button text='Log ind for at spille'
        size='large'
        variant='light'
        as={'a'}
        className={'inline-block'}
        href={getLoginUrl({ successUrl: appendParameter('launch') }) }
      />
    </TopSection>
    );
  };

  const TopSectionForMobileAndTablet = ({ showImage }: Pick<TopSectionProps, 'showImage'>) => {
    return <TopSection gameServiceId={game.gameServiceId} foregroundUrl={gameData?.data?.graphics?.foreground?.url ?? ''} gameType={game.type.name} backgroundUrl={backgroundUrl} gameIsPending={gameData.isPending} isLoggedIn={isLoggedIn} showImage={showImage} gameTitle={gameData?.data?.title ?? ''} liveStreamLink={gameData?.data?.stream?.videoStreamUrl as string} isTableClosed={isTableClosed}>
      {isTableClosed && (
        <CountDown
          className='relative my-auto text-white text-center !justify-center self-center w-full text-28 mb-55'
          openingTime={openFrom ?? ''}
          paragraphSize={28}
        />
      )}
      <Header as='h1' size='2xl' className='text-white font-extrabold mb-12' italic={true}
        truncate={2}>{gameData?.data?.title ?? ''}</Header>
      <Paragraph
        size={14}
        weight='bold'
        className='text-white mb-32'>{gameData?.data?.tagLine ?? ''}
      </Paragraph>
      {!isTableClosed &&
       <div data-tracking-meta-data={JSON.stringify({
         action: 'game open',
         label: 'mobile',
         gameTitle: gameData?.data?.title,
         category: gameData?.data?.tagLine
       })}
       onClick={track}
       >
         <Button className='w-165'
           size='medium'
           text={ctaText}
           variant='secondary'
           onClick={() => {
             appendParameterAutomatically('launch');
             if (!isLoggedIn) {
               window.location.href = getLoginUrl({});
             }
           }}
         />
       </div>
      }
    </TopSection>;
  };

  const GameFrame = () => {
    hideUserBalance();
    return <iframe className='w-full bg-black z-50 h-full border-0 landscape:group-[.is-fullscreen]:group-[.is-mobile-detected]:pt-40 landscape:group-[.is-fullscreen]:group-[.is-mobile-detected]:max-h-screen is-tablet-detected:aspect-[calc(16/5)] is-fullscreen:h-full' src={gameLaunchUrl ?? '' }/>;
  };

  const GameLauncherContent = ({ showImage = true, hasScreenName = false, ignoreScreenNameData = false }: Pick<TopSectionProps, 'showImage' | 'hasScreenName' | 'ignoreScreenNameData'>) => {
    if ((!isLoggedIn || !hasScreenName) &&  !ignoreScreenNameData && isEntainGame && isDesktop()) { // Entain Desktop
      return <TopSectionForNotLoggedInOrMissingScreenName hasScreenName={hasScreenName} showImage={showImage}/>;
    } else if (!isLoggedIn && !isEntainGame && isDesktop()) { // not Entain desktop
      return <TopSectionForNotLoggedInOrMissingScreenName hasScreenName={true} showImage={showImage}/>;
    } else if ((!isDesktop() && !parameterAutoLaunch) || (!isDesktop() && !isLoggedIn)) { // tablet and mobile
      return <TopSectionForMobileAndTablet showImage={showImage}  />;
    } else if (playForMoney.isLoading) {
      return <GameLoader/>;
    } else { // game iframe
      return <GameFrame/>;
    }
  };

  if ((!isDesktop() && !queryParameterChanged) || (!isDesktop() && !isLoggedIn)) { // if queryparameter has not been changed with event, and this is not desktop
    return <GameLauncherContent hasScreenName={hasScreenName}/>;
  }

  // launch view
  const wrapperBackgroundUrl = gameData?.data?.graphics?.background?.url
    ? { backgroundImage: `url(${gameData.data.graphics.background.url})` }
    : {};
  return <div className='grid grid-cols-1 lg:grid-cols-[1fr_16rem] is-fullscreen:grid-cols-[1fr] gap-10 mb-20'>
    <div ref={gameLauncherRef} id='gamelauncherWrapper' style={wrapperBackgroundUrl} className={`h-screen w-full grid grid-rows-[50px_auto_0] border-t-0 border-b-0 overflow-hidden
     sm:grid-rows-[50px_auto_0] sm:h-576 sm:max-h-[45vw] sm:rounded-32 sm:bg-cover
     md:h-592 md:min-w-512 md:max-h-[40vw]
     lg:grid-rows-[50px_auto_0] lg:h-672
     is-tablet-detected:w-full is-tablet-detected:max-h-[50vw]
     is-fullscreen:grid-rows-[88px_auto_0] is-fullscreen:rounded-none is-fullscreen:h-dvh is-fullscreen:!max-h-screen
     group-[.is-fullscreen]:group-[.is-tablet-detected]:max-h-screen group-[.is-fullscreen]:group-[.is-tablet-detected]:mt-6
     group-[.is-fullscreen]:group-[.is-desktop-detected]:max-h-screen
     landscape:group-[.is-fullscreen]:group-[.is-mobile-detected]:grid-cols-[60px_auto_0] landscape:group-[.is-fullscreen]:group-[.is-mobile-detected]:grid-rows-[auto] landscape:group-[.is-fullscreen]:group-[.is-mobile-detected]:w-auto landscape:group-[.is-fullscreen]:group-[.is-mobile-detected]:h-svh`}>
      <div className='z-[60] sm:backdrop-blur sm:rounded-t-32 is-fullscreen:rounded-t-none is-fullscreen:flex is-fullscreen:flex-col is-fullscreen:justify-end  group-[.is-fullscreen]:group-[.is-mobile-detected]:backdrop-blur group-[.is-fullscreen]:group-[.is-mobile-detected]:z-50'>
        <div className='sm:absolute sm:inset-0 sm:bg-black sm:opacity-50 group-[.is-fullscreen]:group-[.is-mobile-detected]:opacity-0 group-[.is-fullscreen]:group-[.is-mobile-detected]:absolute group-[.is-fullscreen]:group-[.is-mobile-detected]:inset-0 group-[.is-fullscreen]:group-[.is-mobile-detected]:bg-black'/>
        <GameLauncherHeader gameTitle={gameData?.data?.title ?? ''} isLoggedIn={isLoggedIn} game={game} paymentLink={paymentLink} toggleFullscreen={toggleFullscreen} returnUrl={returnUrl} gameType={game.type.name}/>
      </div>
      <GameLauncherContent  ignoreScreenNameData={ignoreScreenNameData} hasScreenName={hasScreenName} showImage={ !isLoggedIn } />
      <div className='hidden backdrop-blur sm:block md:rounded-b-32 group-[.is-fullscreen]:group-[.is-mobile-detected]:block'>
        <div className='absolute inset-0 bg-black opacity-50 group-[.is-fullscreen]:group-[.is-mobile-detected]:opacity-0 group-[.is-fullscreen]:group-[.is-mobile-detected]:relative'/>
      </div>
    </div>
    <MicroTileSlider relatedToGameId={game.gameServiceId} gameLauncherRef={gameLauncherRef}/>
  </div>;
};

export default GameLauncher;
