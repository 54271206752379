import React, { useEffect, useState } from 'react';
import { Button } from '../../Components/Button/Button';
import { AddCoins, Close, CloseLarger, CloseFullscreen, Fullscreen } from '../../../assets/icons/svg';
import { Icon } from '../../Components/Icon/Icon';
import { PaymentLink, ShortGame } from './GameLauncher';
import { isDesktop, isMobile } from '@SharedScripts/Utils/DeviceClass';
import { removeParameter } from '@SharedScripts/Utils/Url';
import { track } from '../../helpers/tracking';
import useQueryParameterChange from '@SharedScripts/Hooks/useQueryParameterChange';

interface GameLauncherHeaderProps {
  game: ShortGame,
  gameTitle: string,
  paymentLink?: PaymentLink,
  returnUrl?: string,
  toggleFullscreen: () => void,
  isLoggedIn?: boolean;
  gameType: string;
}

interface HeaderControlsProps {
  returnUrl?: string,
  paymentLink?: PaymentLink,
  isLoggedIn?: boolean,
}

const GameLauncherHeader = ({ gameTitle, gameType, paymentLink, returnUrl, toggleFullscreen, isLoggedIn = false }: GameLauncherHeaderProps) => {
  const [fullscreenText, setFullscreenText] = useState('Fuld skærm');
  const [fullscreenTrackingAction, setFullscreenTrackingAction] = useState('fuld skaerm');
  const fullscreen = useQueryParameterChange('fullscreen');

  const currentUrl = window.location.href;
  const currentUrlWithoutParams = currentUrl.split('?')[0];

  if (!returnUrl || returnUrl === currentUrlWithoutParams) {
    returnUrl = `/casino${gameType === 'Live' ? '/live' : ''}`;
  }

  useEffect(() => {
    setFullscreenText(fullscreen ? 'Luk fuld skærm' : 'Fuld skærm');
    setFullscreenTrackingAction(fullscreen ? 'luk fuld skaerm' : 'fuld skaerm');
  }, [fullscreen]);

  const handleFullscreen = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    toggleFullscreen();
    track(e);
  };

  const closeAndRedirectToReturnUrlDesktop = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    track(event);

    if (!returnUrl || returnUrl === currentUrlWithoutParams) {
      event.preventDefault();

      if (fullscreen) {
        toggleFullscreen();
      }
    }
  };

  const closeAndRedirectToReturnUrlMobile = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    track(event);

    if (!returnUrl || returnUrl === currentUrlWithoutParams) {
      event.preventDefault();
      window.location.href = removeParameter('launch');
    }
  };

  const RightHeaderControls = ({ paymentLink }: HeaderControlsProps) => {
    if (isMobile()) {
      return <div className={`landscape:group-[.is-fullscreen]:group-[.is-mobile-detected]:mb-50 ${isLoggedIn ? 'cursor-pointer' : ''}`}>
        <Icon className='h-30 w-30 fill-white' icon={<AddCoins />}  onClick={() => { window.location.href = paymentLink?.url ?? ''; }}/>
      </div>;
    }

    if (!isDesktop) {
      return <Button className='' variant='primary' size='small' onClick={() => { window.location.href = paymentLink?.url ?? ''; }} text={paymentLink?.text ?? ''}></Button>;
    }

    return (
      <div className={`flex items-center self-center gap-10 ${isLoggedIn ? 'cursor-pointer' : ''}`}>
        <Button className='' variant='primary' size='small' onClick={() => { window.location.href = paymentLink?.url ?? ''; }} text={paymentLink?.text ?? ''}></Button>
      </div>
    );
  };

  const HeaderControlHoverText = ({ text }: { text: string }) => {
    return <div className={`
      opacity-0
      invisible
      group-hover/header-control:opacity-100
      group-hover/header-control:visible
      absolute 
      top-full 
      left-1/2 
      -translate-x-1/2 
      bg-white 
      text-black 
      text-12 
      py-8 
      px-10 
      rounded-6 
      whitespace-nowrap 
      ease-in-out
      duration-200
      before:border-solid
      before:border-transparent
      before:border-t-0
      before:border-l-8
      before:border-b-8
      before:border-b-white
      before:border-r-8
      before:absolute
      before:bottom-full
      before:left-1/2
      before:-translate-x-1/2
      before:translate-y-2
    `}>
      {text}
    </div>;
  };

  const LeftHeaderControls = () => {
    if (isDesktop()) {
      return <div className={'flex gap-12'}>
        <a
          href={returnUrl}
          className={`group/header-control relative text-white ${isLoggedIn ? 'cursor-pointer' : ''}`}
          onClick={(e) => closeAndRedirectToReturnUrlDesktop(e)}
          data-tracking-meta-data={JSON.stringify({
            action: 'close game',
            category: 'gamelauncher',
            label: 'Luk spil desktop',
            gameTitle: gameTitle
          })}
        >
          <Icon
            icon={<CloseLarger/>}
            size={'medium'}
            className={'stroke-[#E5E8EF] group-hover/header-control:stroke-white ease-in-out duration-300 text-white 4 p-6'}
          />
          {<HeaderControlHoverText text={'Luk spil'}/>}
        </a>

        <div
          className={`group/header-control relative flex items-center self-center gap-10 ${isLoggedIn ? 'cursor-pointer' : ''}`}
          onClick={(e) => handleFullscreen(e)}
          data-tracking-meta-data={JSON.stringify({
            action: fullscreenTrackingAction,
            category: 'gamelauncher',
            label: fullscreenText,
            gameTitle: gameTitle
          })}
        >
          <Icon
            icon={fullscreen ? <CloseFullscreen/> : <Fullscreen/>}
            size={'medium'}
            className={'stroke-[#E5E8EF] group-hover/header-control:stroke-white ease-in-out duration-300 text-white 4 p-6'}
          />
          {<HeaderControlHoverText text={fullscreenText}/>}
        </div>
      </div>;
    } else {
      return <a
        href={returnUrl}
        className={`flex text-white items-center self-center gap-10 landscape:group-[.is-fullscreen]:group-[.is-mobile-detected]:flex-col ${isLoggedIn ? 'cursor-pointer' : ''}`}
        onClick={(e) => closeAndRedirectToReturnUrlMobile(e)}
        data-tracking-meta-data={JSON.stringify({
          action: 'close game',
          category: 'gamelauncher',
          label: 'Luk spil mobile',
          gameTitle: gameTitle
        })}
      >
        <Icon
          icon={<Close/>}
          size={'medium'}
          className={'stroke-white text-white 4 p-6'}
        />
        <div className='mt-2 text-12 landscape:group-[.is-fullscreen]:group-[.is-mobile-detected]:text-center'>Luk spil</div>
      </a>;
    }
  };

  return <div
    className={`bg-transparent h-[51px] w-full flex justify-between text-white items-center py-6 px-32 sm:absolute ${!isLoggedIn ? 'is-desktop-detected:pointer-events-none is-desktop-detected:bg-black is-desktop-detected:opacity-50' : ''}
    group-[.is-fullscreen]:group-[.is-mobile-detected]:bg-transparent  group-[.is-fullscreen]:group-[.is-mobile-detected]:relative is-mobile-detected:h-full is-mobile-detected:pt-48 landscape:group-[.is-fullscreen]:group-[.is-mobile-detected]:flex-col`}>
    <LeftHeaderControls/>
    <div data-tracking-meta-data={JSON.stringify({
      action: 'deposit click',
      category: 'gamelauncher',
      label: paymentLink?.text ?? '',
      gameTitle: gameTitle
    })}
    onClick={track}
    >
      <RightHeaderControls paymentLink={paymentLink}/>
    </div>
  </div>
  ;
};

export default GameLauncherHeader;
