﻿import { useQuery } from '@tanstack/react-query';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { getRelated } from '../../api/CasinoApi';
import { Icon } from '../../Components/Icon/Icon';
import { DynamicTile } from '../../Components/Tiles/DynamicTile';
import { gameDataAdapter } from '../../Components/Tiles/gameDataAdapter';
import { TileProps, TileTypesEnum } from '../../Components/Tiles/tiles';
import { GAMES_RELATED } from '../../helpers/tileHelper';
import { ChevronDown, ChevronUp } from '../../../assets/icons/svg';
import { useLiveData } from '../../hooks';
import { track } from '../../helpers/tracking';
import useQueryParameterChange from '@SharedScripts/Hooks/useQueryParameterChange';

type MicroTileSliderProps = {
  relatedToGameId: string;
  gameLauncherRef: RefObject<HTMLDivElement>;
}

const MicroTileSlider = ({ relatedToGameId, gameLauncherRef }: MicroTileSliderProps) => {
  const [showTopFade, setShowTopFade] = useState(false);
  const [showBottomFade, setShowBottomFade] = useState(true);
  const scrollContainer = useRef<HTMLDivElement>(null);
  const lastTile = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(500);
  const fullscreen = useQueryParameterChange('fullscreen');

  const { data: games, isPending } = useQuery({
    queryKey: ['related', relatedToGameId],
    queryFn: () => getRelated(relatedToGameId),
  });

  const setGameLauncherHeight = () => {
    if (!gameLauncherRef.current) return;
    setHeight(gameLauncherRef.current.offsetHeight ?? 30);
  };

  useEffect(() => {
    if (!gameLauncherRef.current) return;
    setHeight(gameLauncherRef.current.offsetHeight ?? 30);
  }, [fullscreen]);

  useEffect(() => {
    // Micro slider gets initialized before GameLauncher, so we have to keep trying
    const interval = setInterval(() => {
      if (gameLauncherRef.current) {
        clearInterval(interval);
        setGameLauncherHeight();
      }
    }, 100);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setGameLauncherHeight, false);

    return () => {
      window.removeEventListener('resize', setGameLauncherHeight, false);
    };
  }, []);

  const liveData = useLiveData(games ?? []);

  const handleScroll = (direction: 'next' | 'previous') => {
    if (!scrollContainer.current) return;

    const currentScrollPosition = scrollContainer.current.scrollTop;
    const scrollBy = ((scrollContainer.current.getBoundingClientRect().height - 112) * (direction === 'next' ? 1 : -1)) + currentScrollPosition;

    scrollContainer.current.scrollTo({
      top: scrollBy,
      behavior: 'smooth'
    });
  };

  const toggleFades = () => {
    if (!scrollContainer.current || !lastTile.current) return;

    const currentScrollPosition = scrollContainer.current.scrollTop;

    setShowTopFade(currentScrollPosition !== 0);
    setShowBottomFade(lastTile.current.getBoundingClientRect().bottom !== scrollContainer.current.getBoundingClientRect().bottom);
  };

  useEffect(() => {
    if (!scrollContainer.current) return;

    scrollContainer.current.addEventListener('scroll', toggleFades);
    toggleFades();
    return () => scrollContainer.current?.removeEventListener('scroll', toggleFades);
  }, []);

  const header = useRef<HTMLDivElement>(null);

  // Handles tile loading state
  const showLoadingState = isPending; // && (!games || games?.length === 0); -- May be redundant. Left here for easy revert
  const gamesWithFallback = showLoadingState ? new Array(6).fill({} as TileProps) : games;

  // the margin top and bottom on the header element is 30px total to add to the getBoundingClientRect value
  const calculatedHeight = (height - (header?.current ? (header?.current?.getBoundingClientRect?.().height + 30) : 0));

  return <aside
    className={'hidden lg:block relative h-full overflow-hidden group-[.is-fullscreen]:hidden'}
    style={{ height: `${height}px` }}
  >
    <div ref={header}>
      <h2
        className='mb-16 mt-14 text-14 text-center relative z-10'
      >{GAMES_RELATED}</h2>
    </div>
    <div
      className={`relative z-0 origin-[64px_calc(50%_-_8px)] after:pointer-events-none after:duration-200 before:duration-200
      ${!showTopFade ? 'before:opacity-0' : 'before:opacity-100'} before:left-0 before:right-0 before:top-0 before:h-52 before:absolute
      before:bg-gradient-to-b before:from-neutrals-coolGrey-100 before:from-20% before:z-50
      ${!showBottomFade ? 'after:opacity-0' : 'after:opacity-100'} after:right-0 after:left-0 after:h-52 after:bottom-0 after:absolute
       after:bg-gradient-to-t after:from-neutrals-coolGrey-100 after:from-20% after:z-50`}
      style={{ height: calculatedHeight + 'px' }}
    >
      <div
        className={'flex flex-col items-center no-scrollbar overflow-y-scroll snap-y snap-mandatory h-full mx-auto mt-16 w-full duration-200'}
        ref={scrollContainer}
      >
        {gamesWithFallback?.length && gamesWithFallback.map((game, index) => {
          const isLastTile = games && index === games?.length - 1;
          if (!Object.keys(game).length) { // exit if object is empty (for example the 6 initiated objects)
            return;
          }

          const adapted = gameDataAdapter({
            elementId: null,
            gameData: game,
            liveData: liveData.find((live) => live?.id === game.id),
            isLoading: isPending,
          });
          return <div ref={isLastTile ? lastTile : null}
            key={game.id}
            data-tracking-meta-data={JSON.stringify({
              value: index + 1,
              category: GAMES_RELATED,
            })}
          >
            <DynamicTile
              className={`duration-0 ${isLastTile ? 'snap-end' : 'snap-start'}`}
              type={TileTypesEnum.MICRO}
              data={adapted}
            />
          </div>;
        })}
      </div>
    </div>
    <div className={'flex justify-between flex-col absolute pointer-events-none inset-0 items-center'}>
      <button
        data-tracking-meta-data={JSON.stringify({
          action: 'similar games arrow click',
          category: GAMES_RELATED,
          label: 'previous',
        })}
        className={`${!showTopFade ? 'opacity-0 pointer-events-none' : 'pointer-events-auto'} duration-100 relative top-45 z-50 bg-white border-none rounded-full size-32 flex justify-center cursor-pointer items-center p-0 active:translate-y-1`}
        onClick={(e) => {
          handleScroll('previous');
          track(e);
        }}
      >
        <Icon
          className={'*:stroke-neutrals-grey-950'}
          icon={<ChevronUp/>}
          size={'large'}
        ></Icon>
      </button>
      <button
        data-tracking-meta-data={JSON.stringify({
          action: 'similar games arrow click',
          category: GAMES_RELATED,
          label: 'next',
        })}
        className={`${!showBottomFade ? 'opacity-0 pointer-events-none' : 'pointer-events-auto'} relative z-50 bg-white border-none rounded-full size-32 flex justify-center cursor-pointer items-center p-0 active:translate-y-1`}
        onClick={(e) => {
          handleScroll('next');
          track(e);
        }}
      >
        <Icon
          className={'*:stroke-neutrals-grey-950'}
          icon={<ChevronDown/>}
          size={'large'}
        ></Icon>
      </button>
    </div>
  </aside>;
};

export default MicroTileSlider;
